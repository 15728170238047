.tab-container {
  margin: 6px 0;
  display: flex;
  justify-content: flex-start;
  border-bottom: 2px solid var(--tertiary-mid);
}

.tab-container.current {
  border-bottom-color: var(--secondary-background);
}

.tab-container button.btn.you-data,
.tab-container button.btn.user-data,
.tab-container button.btn.active {
  color: white;
  opacity: 0.4;
  margin-right: 8px;
  border: none;
  border-radius: 12px 12px 0 0;
  position: relative;
  overflow: hidden;
}

.tab-container button.btn.active {
  opacity: 1;
}

.tab-container .beta span {
  position: absolute;
  bottom: 10px;
  left: -15px;
  padding: 0 0 20px 4px;
  background-color: var(--tertiary-color);
  color: white;
  font-weight: bold;
  font-size: 10px;
  transform: rotate(-90deg);
  width: 40px;
  height: 20px;
  line-height: 25px;
  text-align: left;
  text-transform: uppercase;
}

.tab-container button.beta {
  padding-left: 20px;
}

/* button colors */

.tab-container button.btn.user-data {
  background-color: var(--tertiary-mid);
}
