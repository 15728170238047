section#search-results {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--main-max-width);
  margin-left: auto;
  margin-right: auto;
}

#search-results a.result,
#search-results div.result a {
  font-size: 1.1rem;
}

#search-results div.result div.username {
  text-align: left;
  padding: 0;
}

#search-results svg {
  font-size: 1rem;
  margin-right: 6px;
}

div.results-row {
  margin-bottom: 20px;
  padding-top: 6px;
}

div.results-row div.doesnt-exist {
  color: white;
  width: auto;
}

div.movie-result {
  color: white;
  font-size: 1.1rem;
}

div.movie-result .user-data {
  font-size: 1rem;
  margin-left: 10px;
}
