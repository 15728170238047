h1.homepage-title {
  font-size: 2.5rem;
  line-height: 3rem;
  margin: 0 0 12px;
  text-align: center;
  text-shadow: 2px 2px var(--tertiary-color);
}

.lead {
  display: none;
  color: white;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-bottom: 16px;
}

.lead span.extra {
  display: none;
}

div#sub-lists {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

div#popular-polls,
div#recent-polls {
  margin-top: 40px;
  width: 100%;
  max-width: 500px;
}

div#popular-polls h2,
div#recent-polls h2 {
  text-align: center;
  padding-bottom: 6px;
  font-size: 1.2rem;
}

div#popular-polls ul,
div#recent-polls ul {
  margin: 0;
  padding: 0;
}

div#popular-polls li,
div#recent-polls li {
  list-style-type: none;
  width: 100%;
  margin-bottom: 6px;
}

div#popular-polls a,
div#recent-polls a {
  font-size: 1.1rem;
  width: 100%;
  display: block;
}

div#sub-lists svg {
  margin-right: 6px;
}

div#sub-lists .list-member-12 span,
div#sub-lists .list-member-12:hover span {
  font-weight: normal;
  font-size: 0.9rem;
  margin-left: 10px;
  color: white;
}

/* media queries to size headline and show/hide extra texg */

@media screen and (min-width: 600px) {
  .lead {
    display: block;
  }

  h1.homepage-title {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  h1.homepage-title {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  .lead {
    margin-top: 6px;
    margin-bottom: 24px;
  }

  .lead span.extra {
    display: block;
  }

  div#popular-polls,
  div#recent-polls {
    width: 49%;
  }
}
