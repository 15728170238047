section#top-movies {
  width: 100%;
  margin: 20px auto 0;
}

section#top-movies tr.list-member-12 {
  border-bottom: 6px solid var(--page-background);
}

section#top-movies tr.list-member-12 td {
  background-color: transparent;
  color: white;
  border: 0;
  line-height: 1rem;
}

section#top-movies tr.list-member-12 td span {
  white-space: nowrap;
  margin-left: 10px;
}

section#top-movies tr.list-member-12 td.rank {
  border-radius: 12px 0 0 12px;
  width: 65px;
  text-align: right;
}

section#top-movies tr.list-member-12 td.imdb-link {
  border-radius: 0 12px 12px 0;
  text-align: right;
  padding-right: 12px;
}

section#top-movies tr.list-member-12 td.imdb-link a {
  font-size: 0.9rem;
}

@media screen and (min-width: 400px) {
  section#top-movies {
    width: 90%;
  }
}

@media screen and (min-width: 650px) {
  section#top-movies {
    width: 75%;
  }
}

@media screen and (min-width: 900px) {
  section#top-movies {
    width: 50%;
  }
}
