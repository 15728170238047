div#user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 300;
  padding: 3px 2px 1px;
  background-color: var(--tertiary-color);
}

div#user-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div#user-menu li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--tertiary-color);
  background-color: var(--tertiary-background);
  color: white;
}

div#user-menu ul:last-child {
  border-bottom: 0;
  border-radius: 0 0 5px 5px;
}

/* more specificity to overcome bootstrap nav styling */

nav div#user-menu li a {
  display: block;
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: normal;
  padding: 8px 15px;
}

nav div#user-menu li a:hover {
  background-color: var(--tertiary-color);
  color: var(--tertiary-background);
}

/* current a-style, b-style */

nav div#user-menu .create-link {
  display: none;
}

.style-b nav div#user-menu .create-link {
  display: block;
}
