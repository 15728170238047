/* custom CSS here */

.double-slider {
  width: 100%;
  min-width: 1vh;
}

.double-slider .inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.double-slider input {
  pointer-events: none;
  position: absolute;
  height: 0;
  outline: none;
  padding: 0;
  width: 100%;
}

.slider {
  position: relative;
  width: 100%;
  top: -5px;
}

.slider-track,
.slider-range,
.slider-left-value,
.slider-right-value {
  position: absolute;
}

.slider-track,
.slider-range {
  top: -1px;
  border-radius: 3px;
  height: 8px;
}

.slider-range {
  top: 1px;
}

.slider-track {
  background-color: #ffffff;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 2px;
  z-index: 1;
}

.slider-range {
  z-index: 2;
}

/* Removing the default appearance */
.double-slider input,
.double-slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.double-slider .input-zindex-3 {
  z-index: 3;
}

.double-slider .input-zindex-4 {
  z-index: 4;
}

.double-slider .input-zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.double-slider input::-webkit-slider-thumb {
  background-color: var(--bs-blue);
  border: none;
  border-radius: 50%;
  /* box-shadow: 0 0 1px 1px #ced4da; */
  cursor: pointer;
  height: 16px;
  width: 16px;
  /* margin-top: 4px; */
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.double-slider input::-moz-range-thumb {
  background-color: var(--bs-blue);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  width: 16px;
  pointer-events: all;
  position: relative;
}

.slider-container input {
  background-color: transparent;
}

/* styles for key */

section div.legend {
  width: 92%;
  margin: 10px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.75em;
}

section div.legend span.upper,
section div.legend span.lower {
  margin: 0;
  color: white;
}

/* for title and value */

.double-slider legend.sliderTitle {
  display: inline-block;
  vertical-align: baseline;
  color: var(--link-hover);
  font-size: 0.9em;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 17px;
}

legend.sliderTitle span.val {
  color: white;
  margin-left: 8px;
  font-weight: bold;
  font-style: italic;
  font-size: 0.75em;
  text-align: right;
  display: inline-block;
  vertical-align: baseline;
}
