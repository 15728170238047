div#reset-password-modal {
  background-color: rgba(255, 255, 255, 0.7);
}

div#reset-password-modal div#reset-contents {
  position: absolute;
  width: 250px;
  margin-left: -125px;
  left: 50%;
  top: 150px;
  padding: 12px 2rem 12px 16px;
  font-size: 0.9rem;
  background-color: var(--page-background);
  color: white;
  border-radius: 12px 6px 12px 12px;
}

div#reset-password-modal div.alert {
  padding: 8px;
}

div#reset-password-modal input {
  margin-top: 12px;
}

div#reset-password-modal div svg {
  position: absolute;
  right: 3px;
  top: 3px;
  height: 1.2rem;
}
