body footer {
  text-align: center;
  color: white;
  padding: 1px 0 8px;
  border-top: 3px solid var(--tertiary-color);
  bottom: 0;
  margin-top: 90px;
  width: calc(100vw - 40px);
  line-height: 2rem;
  background-color: var(--page-background);
  z-index: 200;
  font-size: 1rem;
}

body footer div {
  margin-top: 6px;
}

body footer span {
  display: none;
}

body footer img {
  vertical-align: baseline;
  position: relative;
  top: 2px;
  margin: 0 4px;
}

body footer a#to-tv-api {
  margin-left: 6px;
  font-weight: bold;
  color: var(--link-hover);
}

body footer a#to-tv-api:hover {
  color: var(--link);
}

body footer #footer-text {
  letter-spacing: 0.22rem;
  text-transform: uppercase;
  font-size: 0.7rem;
  line-height: 0.8rem;
  margin: 5px 0 8px;
  display: none;
}

body footer ul {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

body footer li {
  list-style-type: none;
  padding: 0 15px 4px;
}

body footer li a {
  font-weight: 300;
  font-size: 0.8rem;
}

@media screen and (min-width: 400px) {
  body footer span {
    display: inline;
  }

  body footer #footer-text {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  body footer #footer-text {
    display: block;
  }

  body footer div {
    margin-top: 12px;
  }
}
