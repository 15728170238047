input {
  margin-bottom: 0;
}

input + div.limit {
  color: white;
  text-align: right;
  font-size: 0.75em;
}

label {
  color: white;
  display: block;
  margin: 10px 0 0;
}
