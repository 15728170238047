#question {
  position: relative;
  text-align: center;
}

#question p.desc {
  color: white;
  margin: 0;
}

#question p.uName {
  font-size: 0.9rem;
}
