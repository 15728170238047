.option {
  position: relative;
  display: flex;
  margin-top: 15px;
  color: white;
  padding: 0;
  border: 1px solid;
}

.option .title {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0 20px 10px;
}

.option.list-member-12 {
  padding: 0;
  margin-top: 15px;
}

.option .title h2 button {
  text-transform: uppercase;
  font-size: 1rem;
}

.option .opt-container {
  /* on small devices, votes/select button goes on bottom */
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}

.option .title span.year {
  font-size: 0.9rem;
  color: var(--dark-text);
}

/* for accordions */

.option div.accordion.accordion-flush {
  width: 100%;
  background-color: transparent;
  padding-right: 0;
}

.option div.accordion .accordion-header button,
.option div.accordion .accordion-item {
  background-color: transparent;
  color: white;
}

.option div.accordion-item button.accordion-button {
  padding: 0 0 0 20px;
}

/* for voting form */

.option fieldset {
  height: 0;
  overflow: hidden;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: height 0.5s;
}

.option.selected fieldset {
  height: 172px;
}

.option fieldset button {
  margin-top: 6px;
  width: 80px;
}

.option div.limit {
  margin-top: -6px;
  padding-left: 90px;
}

.option div.textarea {
  width: 100%;
  height: 100px;
  margin-top: 12px;
}

.option div.textarea textarea {
  font-size: 0.9rem;
  width: 100%;
  height: 100px;
}

/* for the selector or vote display */

.option button.picker,
.option div.tab {
  flex-grow: 0.15;
  min-width: 100px;
  height: 100%;
  border-radius: 0 0 12px 12px;
  white-space: nowrap;
  position: relative;
  left: 12px;
  font-size: 0.9em;
}

.option button.picker {
  color: white !important;
}

.option div.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  white-space: normal;
  padding: 0 12px 3px;
}

.option.winner {
  border: 2px solid var(--highlight);
}

.option.winner div.tab {
  background-color: var(--highlight);
  color: white;
  top: 2px;
}

.option .accordion-body {
  display: flex;
  font-size: 0.9rem;
  line-height: 1.1rem;
  align-items: flext-start;
  flex-direction: row;
  margin: 6px 0 0;
  padding: 6px 0 0 20px;
  margin: 0;
  overflow: hidden;
  transition: max-height 1s;
}

.option .accordion-body .info-links {
  margin-top: 6px;
}

.option .accordion-body a {
  font-size: 1em;
  font-weight: bold;
  margin-right: 10px;
  text-decoration: none;
}

.option .accordion-body strong {
  margin-right: 12px;
  color: var(--dark-text);
}

.option .accordion-body img.poster {
  display: none;
}

/* .option.selected {
  background-color: var(--item-background-hover);
} */

.option div.misc-info {
  margin: 6px 0;
  display: flex;
}

.option div.misc-info span {
  margin-right: 10px;
}

@media screen and (min-width: 400px) {
  /* on larger screens, put select button/tab on right and alter styling */
  .option button.picker,
  .option div.tab {
    border-radius: 0 12px 12px 0;
  }

  .option.winner div.tab {
    top: 0;
    left: 14px;
  }

  .option .title {
    padding-right: 10px;
  }

  .option {
    border: none;
  }

  .option .opt-container {
    flex-direction: row;
  }
}

@media screen and (min-width: 472px) {
  .option .accordion-body img.poster {
    display: block;
    width: 75px;
    margin-right: 12px;
    align-self: flex-start;
  }

  .option .title h2 button {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 650px) {
  .option .accordion-body img.poster {
    width: 100px;
  }
}
