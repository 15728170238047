.card {
  background-color: rgba(0, 0, 0, 0.75);
  background-position: 50% 15%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: none;
  transition: box-shadow, 0.15s;
}

.card-col {
  border-radius: 6px;
}

.card .card-check {
  display: none;
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 2rem;
}

.card.first-card .card-check {
  display: block;
}

.card.first-card .title-check {
  display: none;
}

.card .title-check {
  margin-right: 6px;
}

.card a.main-link {
  flex-grow: 1;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 6px;
}

.card a.main-link h5 {
  background-color: black;
  border-radius: 6px 6px 0 0;
  padding: 10px 6px 0;
  text-align: center;
  margin: 0;
  border: none;
  font-size: 1.1rem;
}

.card.first-card a.main-link h5 {
  /* min-height: 200px; */
  background-color: transparent;
  padding-top: 130px;
  background-image: linear-gradient(transparent, black 90%, black);
}

.card a.main-link:hover h5 {
  color: var(--link-hover);
}

.card .title-container {
  background-color: black;
}

.card .poll-info {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  background-color: black;
  border-radius: 0 0 6px 6px;
  font-size: 0.9rem;
  padding: 2px 12px 8px;
}

.card .vote-count {
  color: white;
}

.card .poll-info div {
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
}

.card .poll-info div.username {
  padding: 4px;
  margin-top: 4px;
}

.card .vote-count {
  display: none;
}

/* media queries for height of cards */

@media screen and (min-width: 400px) {
  .card .poll-info {
    padding-top: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card .poll-info div.username {
    text-align: left;
  }

  .card .poll-info .vote-count {
    display: block;
    text-align: right;
  }
}

@media screen and (min-width: 992px) {
  .card a.main-link h5 {
    font-size: 1.2rem;
    /* background-color: transparent;
    padding-top: 160px;
    background-image: linear-gradient(transparent, black 90%, black); */
  }
}

@media screen and (max-width: 576px) {
  .card.first-card {
    min-height: 200px;
  }
}

@media screen and (min-width: 576px) {
  .card {
    min-height: 250px;
    background-position: 50% 25%;
  }
  .card .poll-info {
    flex-direction: column;
  }
  .card .poll-info .vote-count,
  .card .poll-info div.username {
    text-align: center;
  }
  .card .poll-info div.username {
    padding: 4px 8px;
  }

  .card .card-check {
    display: block;
  }

  .card .title-check {
    display: none;
  }

  .card a.main-link h5 {
    background-color: transparent;
    padding-top: 160px;
    background-image: linear-gradient(transparent, black 90%, black);
  }
}

@media screen and (hover: hover) {
  .card:hover {
    box-shadow: 0 0 25px var(--tertiary-color);
  }
}

@media screen and (max-width: 576px) and (min-width: 400px) {
  .card .poll-info {
    align-items: flex-end;
  }

  .card .poll-info .you-data.username,
  .card .poll-info .user-data.username {
    width: auto;
    white-space: nowrap;
    padding: 4px 10px;
  }

  .card .vote-count {
    width: auto;
  }
}

@media screen and (min-height: 800px) and (min-width: 600px) {
  .card {
    min-height: 400px;
    background-position: 50% 0;
  }
}
