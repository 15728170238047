section#login {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: var(--main-max-width);
}

section#login div#loginFormContainer,
section#login div#signupFormContainer {
  margin: 0 auto 40px;
  width: 250px;
  padding: 0 10px;
}

section#login input {
  width: 100%;
}

section#login div.alert {
  width: 100%;
  margin: 20px 0 0;
}

section#login button {
  margin-top: 15px;
}
