section#create {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--main-max-width);
}

section#create h5 {
  text-align: center;
  font-size: 1rem;
  margin: 25px auto 12px;
  padding: 0 0 8px;
  border-bottom: 1px solid var(--tertiary-color);
}

section#create h5 span {
  display: block;
  margin: 0;
  font-size: 0.8rem;
  color: var(--dark-text);
  font-weight: normal;
}

section#create p.list-member-12 {
  color: white;
}

section#create p.chat-gpt-disclaimer {
  color: var(--dark-text);
  font-size: 0.8rem;
  line-height: 0.9rem;
  text-align: center;
}

section#create div.tab-container,
section#create div.tab-container button {
  margin-top: 0;
}

section#create div.create-container {
  min-height: 225px;
}

section#create div#about,
section#create div#titleSearch {
  max-width: 400px;
  margin: 0 auto 50px;
}

section#create div.textarea {
  margin-top: 10px;
}

/* section#create .container {
  margin: 0 auto;
} */

section#create button {
  margin-top: 10px;
}

section#create div#about button {
  margin-top: 0;
}

section#create input[type="text"],
section#create textarea {
  width: 100%;
  margin-bottom: 0;
}

/* section#create #released input[type="text"] {
  width: 40%;
  margin-right: 10px;
} */

section#create textarea {
  height: 80px;
  margin-bottom: -5px;
}

section#create ul {
  padding: 0;
  margin: 0;
}

section#create h2 {
  color: white;
}

section#create legend {
  font-size: 1em;
  font-weight: bold;
}

section#create fieldset {
  margin-bottom: 0;
}

section#create li p {
  margin-bottom: 0;
}

section#create ul#selected h4 {
  margin-bottom: 0;
}

section#create div.alert {
  margin-top: 12px;
}

section#create div#resend-alert {
  color: white;
}

section#create div#resend-alert button {
  float: right;
  margin: 0 0 6px 20px;
  font-size: 0.9rem;
  padding: 3px 8px;
}

section#create div#resend-alert p {
  margin-bottom: 0;
  margin-right: 20px;
  font-size: 0.85rem;
}

@media screen and (max-width: 575px) {
  section#create h1 {
    max-width: 376px;
    margin: 0 auto 8px;
  }
}
