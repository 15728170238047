.list {
  position: relative;
}

#user-poll-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#user-poll-list div.deactivated h6 {
  color: var(--link-hover) !important;
  text-decoration: line-through;
  opacity: 0.5;
  margin-bottom: 0;
}

#user-poll-list div.deactivated div {
  color: var(--warning-color);
  font-size: 0.8rem;
}

#user-poll-list .deactivate-link,
#user-poll-list .edit-link {
  color: white;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  font-weight: bold;
}

#user-poll-list .doesnt-exist {
  width: 100%;
}

#user-poll-list .deactivate-link span,
#user-poll-list .edit-link span {
  cursor: pointer;
}

#user-poll-list .deactivate-link span:hover,
#user-poll-list .edit-link span:hover {
  color: var(--link-hover);
}
