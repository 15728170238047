/* for accordion */

section#create div.accordion .accordion-header button,
section#create div.accordion .accordion-item {
  background-color: transparent;
  color: white;
}

section#create div.accordion .accordion-body {
  padding-top: 0;
}

/* for search options */

fieldset.double {
  position: relative;
}

fieldset.double input {
  position: absolute;
}

section#create fieldset#oscars {
  margin-bottom: 0;
}

section#create select.form-select {
  font-size: 1rem;
  margin-bottom: 0;
}

#ratings legend,
#oscars legend,
#genres legend {
  color: var(--link-hover);
}

h2.accordion-header {
  border-radius: 8px 8px 0 0;
}

section#create div.accordion.accordion-flush {
  background-color: var(--item-background-light);
  border-radius: 8px;
}

section#create div.accordion-body {
  padding: 12px;
  border-radius: 0 0 12px 12px;
}

section#create .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  /* box-shadow: none; */
}

.accordion-button:focus {
  box-shadow: none;
  border-bottom: none;
}
