#search-panel {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  z-index: 100;
  padding: 10px;
  border-radius: 0 0 5px 5px;
}

#search-panel.show {
  display: flex;
  flex-direction: row;
  background-color: var(--tertiary-color);
}

#search-panel input {
  margin-right: 10px;
}

#search-panel button.btn.btn-primary {
  font-size: 0.8rem;
  border: none;
  padding: 4px 12px;
}

#search-panel button.btn.btn-primary:hover {
  color: var(--page-background);
}
