div.user-inits {
  color: var(--page-background);
  background-color: var(--link-hover);
  display: inline-block;
  font-size: 0.9rem;
  width: 1.8rem;
  max-width: 1.8rem;
  height: 1.8rem;
  line-height: 1.9rem;
  border-radius: 1.8rem;
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 700;
  position: relative;
  top: -3px;
  text-align: center;
}

div.user-inits:hover {
  background-color: var(--link);
}

div.user-inits.big {
  height: 3.6rem;
  width: 3.6rem;
  max-width: 3.6rem;
  border-radius: 3.6rem;
  font-size: 1.8rem;
  line-height: 3.6rem;
  margin-right: 8px;
  top: 0;
}

div.user-inits.big.user-data {
  background-color: var(--tertiary-mid);
}

div.user-inits.big.user-data:hover {
  background-color: var(--tertiary-mid);
}

div.user-inits.big.you-data:hover {
  background-color: var(--secondary-background);
}
