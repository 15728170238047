.comment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comment p {
  color: white;
  font-size: 0.9rem;
}

.comment span.your-vote {
  font-size: 0.8rem;
  display: block;
  text-align: center;
  margin-top: 6px;
}

.comment .user-info {
  margin-bottom: 10px;
}

li.user-comment {
  width: 100%;
}

li.user-comment a {
  font-size: 1.1rem;
}

li.user-comment p {
  text-align: left;
  width: 100%;
  color: white;
  margin: 6px 0 0;
  font-weight: normal;
  font-size: 0.9rem;
  margin: 0;
}

li.user-comment span.you-data,
li.user-comment span.user-data {
  font-size: 0.9rem;
  font-weight: normal;
  margin-left: 10px;
}

li.user-comment span.your-vote {
  font-weight: bold;
}

/* @media screen and (min-width: 600px) {
  div.user-comment {
    width: 49%;
  }
} */

/* @media screen and (max-width: 576px) {
  .comment div {
    border: none !important;
  }
} */

@media screen and (min-width: 600px) {
  .comment {
    flex-direction: row;
  }

  .comment .user-info {
    width: 33%;
  }

  .comment .comment-text {
    width: 65%;
  }
}
