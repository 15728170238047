.user-poll {
  width: 100%;
}

li.poll-listing,
li.user-listing {
  list-style-type: none;
}

.poll-listing p {
  color: white;
  margin: 0;
  font-size: 0.8rem;
}

.poll-listing a {
  font-size: 1.2rem;
}

.poll-listing span.username.tag a {
  font-size: 0.7rem;
}

.poll-listing span.you-data,
.poll-listing span.user-data,
.user-poll span.you-data,
.user-poll span.poll-info {
  padding: 0;
  margin-left: 10px;
}

.user-poll span.you-data {
  font-size: 0.9rem;
  font-weight: normal;
}

.poll-listing svg,
.user-poll svg {
  font-size: 0.9rem;
  margin-right: 6px;
  position: relative;
  top: -1px;
}

.user-poll span.poll-info {
  color: white;
  font-size: 0.9rem;
  font-weight: normal;
}

.user-poll span.poll-expired,
.poll-listing span.poll-expired {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 8px;
  font-size: 0.6rem;
  background-color: var(--highlight);
  /* color: var(--page-background); */
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
  position: relative;
  top: -1px;
}
