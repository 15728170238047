section#poll {
  max-width: var(--main-max-width);
  margin-left: auto;
  margin-right: auto;
}

p#yourvote {
  margin-top: 15px;
  margin-bottom: 0;
}

section#poll ul {
  margin: 0;
  padding: 0;
}

div#comments {
  margin: 20px auto 0;
  padding: 0;
  max-width: 540px;
}

div.edit-poll {
  display: flex;
  justify-content: center;
  margin-top: 6px;
}

div.edit-poll span {
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}

div.edit-poll span:hover {
  color: var(--link-hover);
}

div.deactivated {
  text-align: center;
  color: white;
}

section#poll div#resend-alert {
  margin: 0 16px;
}

section#poll div#resend-alert button {
  float: right;
  margin: 0 0 10px 20px;
  font-size: 0.9rem;
  padding: 3px 8px;
}

section#poll div#resend-alert p {
  margin-bottom: 0;
  margin-right: 20px;
  text-align: left;
  font-size: 0.85rem;
  color: white;
}

section#poll div.username.user-data {
  margin: 6px 0;
}

section#poll p.poll-status {
  color: var(--highlight-text);
  font-size: 0.8rem;
  margin: 0;
}

@media screen and (min-width: 768px) {
  div#comments {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  div#comments {
    max-width: 800px;
  }
}
