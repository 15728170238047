section#user-directory {
  position: relative;
  max-width: var(--main-max-width);
  margin-left: auto;
  margin-right: auto;
}

section#user-directory ul {
  margin: 0;
  padding: 0;
}

section#user-directory a span {
  display: none;
  margin-left: 10px;
}

section#user-directory a span.tag.you {
  display: inline;
}

section#user-directory a {
  width: 100%;
  display: block;
  font-size: 1.2rem;
}

section#user-directory a span.user-info {
  color: white;
  font-size: 0.9rem;
  font-weight: normal;
}

@media screen and (min-width: 500px) {
  section#user-directory span.user-info {
    display: inline;
  }
}

@media screen and (min-width: 650px) {
  section#user-directory span.tag {
    display: inline;
  }

  section#user-directory a span.tag.you {
    display: none;
  }
}
