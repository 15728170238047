section#directory {
  position: relative;
  max-width: var(--main-max-width);
  margin-left: auto;
  margin-right: auto;
}

section#directory select {
  font-family: Limelight;
  color: white;
  width: 100%;
}

section#directory ul {
  margin: 0;
  padding: 0;
}

section#directory #filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

section#directory span.username {
  white-space: nowrap;
  display: none;
  position: relative;
  top: -3px;
  right: -2px;
  font-size: 0.7rem;
}

section#directory span.your-vote {
  display: none;
  font-size: 0.9rem;
  margin-left: 12px;
}

section#directory div#filters div.doesnt-exist {
  width: 100%;
}

@media screen and (min-width: 450px) {
  section#directory .title-and-user span.username {
    display: inline;
  }
}

@media screen and (min-width: 550px) {
  section#directory .title-and-user span.your-vote {
    display: inline;
  }
}

@media screen and (min-height: 600px) {
  section#directory p.sub-info {
    display: block;
  }
}

@media screen and (min-height: 800px) {
  section#directory p.poll-info {
    display: block;
  }
}
