/* custom css */

section#terms {
  max-width: 500px;
  margin: 20px auto 0;
}

section#terms p {
  color: white;
  font-size: 0.9rem;
}
