.single-slider {
  width: 100%;
  min-width: 1vh;
}

.single-slider input {
  height: 0;
  outline: none;
  padding: 0;
}

/* styles for key */

.single-slider div.legend {
  width: 92%;
  margin: 2px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.75em;
  vertical-align: baseline;
}

section div.legend span.upper,
section div.legend span.lower {
  margin: 0;
  color: white;
}

/* for title and value */

.single-slider legend.sliderTitle {
  display: inline-block;
  vertical-align: baseline;
  color: var(--link-hover);
  font-size: 0.9em;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 0;
}

legend.sliderTitle span.val {
  color: white;
  margin-left: 8px;
  font-weight: bold;
  font-style: italic;
  font-size: 0.75em;
  text-align: right;
  display: inline-block;
  vertical-align: baseline;
}
