div#main-alert {
  width: 250px;
  position: absolute;
  top: 150px;
  left: 50%;
  margin-left: -125px;
}

div#main-alert svg {
  position: absolute;
  top: 3px;
  right: 4px;
  cursor: pointer;
}
