section#profile {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--main-max-width);
  margin-left: auto;
  margin-right: auto;
}

section#profile h1 {
  margin-bottom: 0;
}

section#profile h4 {
  margin: 0 0 8px;
}

section#profile ul {
  padding: 0;
}

section#profile li {
  list-style-type: none;
}

section#profile li p {
  margin-bottom: 0;
}

section#profile li a {
  font-size: 1.1rem;
}

section#profile li.comment a p {
  text-align: left;
  width: 100%;
  color: white;
}

section#profile .no-content {
  color: white;
}

section#profile div.alert {
  padding: 6px 10px;
  width: auto;
  font-size: 0.9rem;
  margin-top: 8px;
}

section#profile div#confirmation-alert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* section#profile button#resend {
  padding: 0;
  border: 0;
  font-weight: bold;
  color: rgb(88, 21, 28);
  background-color: transparent;
  margin-left: 12px;
  text-transform: uppercase;
} */

section#profile div.profile-info div.user-inits {
  float: left;
}

section#profile span.poll-info,
section#profile span.you-data,
section#profile span.user-data {
  display: none;
}

section#profile div#resend-alert {
  color: white;
}

section#profile div#resend-alert button {
  float: right;
  margin: 0 0 10px 20px;
  font-size: 0.9rem;
  padding: 3px 8px;
}

section#profile div#resend-alert p {
  margin-bottom: 0;
  margin-right: 20px;
  text-align: left;
  font-size: 0.85rem;
}

@media screen and (max-width: 600px) {
  section#profile .user-comment span.you-data,
  section#profile .user-comment span.user-data {
    display: block;
    margin-left: 0;
  }
}

@media screen and (min-width: 450px) {
  section#profile span.poll-info {
    display: inline;
  }
}

@media screen and (min-width: 600px) {
  section#profile span.user-data,
  section#profile span.you-data {
    display: inline;
  }
}

@media screen and (max-width: 599px) {
  section#profile .user-poll svg {
    display: inline;
  }
}
