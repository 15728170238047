/* basic nav styles to customize Bootstrap */

nav.navbar {
  padding: 0;
  position: relative;
}

div.navbar-nav {
  justify-content: flex-end;
}

nav.navbar svg:hover {
  color: var(--link-hover);
}

button.navbar-toggler {
  padding: 0 6px;
  margin-bottom: 10px;
}

button.navbar-toggler span.navbar-toggler-icon {
  width: 1em;
}

span.navbar-toggler-icon {
  height: 1.3em;
}

.navbar-nav a.nav-link,
div.navbar-nav span.navbar-text {
  color: var(--link);
  margin-left: 10px;
  text-align: right;
  font-size: 1.2em;
  line-height: 1.5em;
  font-family: "Raleway", sans-serif;
}

nav.navbar div.container {
  align-items: flex-end;
  padding: 0;
  max-width: 100% !important;
}

nav.navbar span.navbar-text {
  text-align: right;
  cursor: pointer;
}

nav.navbar div.me-auto.navbar-nav {
  margin-right: 0 !important;
}

div.navbar-nav a.nav-link:hover {
  color: var(--link-hover);
}

div.navbar-collapse {
  justify-content: flex-end;
}

/* styling for user link and icon */

div.navbar-nav span.navbar-text.user,
div.navbar-nav div.user-icon {
  color: var(--link-hover);
  font-weight: 700;
}

div.navbar-nav span.navbar-text.user.show-menu,
div.navbar-nav div.user-icon.show-menu svg {
  color: var(--link);
}

nav a.nav-link.user svg,
nav span.navbar-text.user svg,
div.navbar-nav div.user-icon svg.caret-right {
  height: 1rem;
  font-size: 0.5rem;
  position: relative;
  top: -1px;
  margin-left: 3px;
  transition: all 0.2s;
}

nav a.nav-link.user.show-menu svg,
nav span.navbar-text.user.show-menu svg,
div.navbar-nav div.user-icon.show-menu svg.caret-right {
  transform: rotate(90deg);
}

div.navbar-nav div.user-icon {
  padding: 9px 0 8px;
  margin-left: 14px;
}

div.navbar-nav div.nav-item.user-icon.click-to-show {
  display: block;
}
div.navbar-nav div.nav-item.user-icon.click-to-navigate {
  display: none;
}

/* a/b testing */
/* 
.style-b div.navbar-nav .create-nav-link {
  display: none;
}

.style-b div.navbar-nav .nav-login-link {
  display: none;
} */

/* media query to determine whether user hovering or clicking */

@media screen and (max-width: 767px) {
  /* div.navbar-nav div.nav-item.user-icon.click-to-show {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 15px;
  }

  .style-b div.navbar-nav div.nav-item.user-icon.click-to-show {
    display: none;
  }

  div.navbar-nav div.nav-item.user-icon.click-to-navigate {
    display: none;
  } */
}

@media screen and (hover: hover) {
  /* screen has hover */
  /* div.navbar-nav div.nav-item.user-icon.click-to-navigate {
    display: none;
  }
  div.navbar-nav div.nav-item.user-icon.click-to-show {
    display: block;
  }

  .style-b div.navbar-nav div.nav-item.user-icon.click-to-navigate,
  .style-b div.navbar-nav div.nav-item.user-icon.click-to-show {
    display: none;
  } */
}

@media screen and (hover: hover) {
  /* screen has hover */
  /* div.navbar-nav div.nav-item.user-icon.click-to-show {
    display: none;
  }
  div.navbar-nav div.nav-item.user-icon.click-to-navigate {
    display: block;
  }

  .style-b div.navbar-nav div.nav-item.user-icon.click-to-navigate,
  .style-b div.navbar-nav div.nav-item.user-icon.click-to-show {
    display: none;
  } */
}

/* media query to adjust alignment of user icon on smaller screens */

@media screen and (max-width: 767px) and (hover: hover) {
  /* screen is small enough to collapse nav */
  /* div.navbar-nav div.nav-item.user-icon.click-to-navigate {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 15px;
  } */
  /* 
  .style-b div.navbar-nav div.nav-item.user-icon.click-to-navigate,
  .style-b div.navbar-nav div.nav-item.user-icon.click-to-show {
    display: none;
  } */
}
