li.search-result {
  list-style-type: none;
  text-align: left;
  cursor: pointer;
  color: white;
}

.search-result h4 {
  font-size: 1em;
  color: var(--link);
  margin-bottom: 5px;
}

.search-result h4 span {
  font-size: 0.9em;
  margin-left: 6px;
  color: var(--dark-text);
}

.search-result p {
  color: var(--dark-text);
  font-size: 0.8em;
}

.search-result span.stars {
  font-weight: bold;
}
